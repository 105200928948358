<template>
  <div class="g-map-wrapper">
    <!-- 地图区域 -->

    <div id="g-container" :style="!state.mapType? { width: '100%', height: '100%' }:{ width: '25%', height: '25%',right:'15px',bottom:'10px',position:'absolute',zIndex:'199',border:'1px solid white' }" />
    <!-- 绘制面板 -->

    <div style="width: 100%;height: 100%" v-show="this.$root.$route.name == ERouterName.WAYLINE ">
      <div class="g-map-yy-2"></div>
      <div class="g-map-yy"></div>
      <div class="g-map-yy-1">

        <a-row style="text-align: center" :style="{color: '#ffffff',marginTop:'10px',width:'35%',fontSize:'18px',fontWeight:'bold'}">
          <a-col :span="6" >
            航线长度
          </a-col>
          <a-col :span="6" >
            预计执行时间
          </a-col>
          <a-col :span="6" >
            航点
          </a-col>
          <a-col :span="6" >
            照片
          </a-col>

          <a-col :span="6" >
            <span v-if="wayLineData.wayLineDis>=5000"> {{ (wayLineData.wayLineDis/1000).toFixed(1) }} km</span>
            <span v-if="wayLineData.wayLineDis<5000"> {{ wayLineData.wayLineDis  }} m</span>
          </a-col>
          <a-col :span="6" >
            <span >{{wayLineData.wayLineTime}}</span>
          </a-col>
          <a-col :span="6" >
            {{ wayLineData.wayLineNum }}
          </a-col>
          <a-col :span="6" >
            {{ wayLineData.wayLinePhotos }}
          </a-col>
        </a-row>

      </div>
      <div class="g-map-db">
        <div class="map-annotation">
          <span class="anno-item">坐标系：WGS 84</span>
        </div>
      </div>
      <span class="way-line-title">{{wayLineData.wayLineName}} </span>
    </div>
    <div
        class="g-action-panel"
        :style="{ right: drawVisible ? '316px' : '16px' }"
    >
      <div :class="state.currentType === 'pin' ? 'g-action-item selection' : 'g-action-item'" @click="draw('pin', true)">
        <a><a-image :src="pin" :preview="false" /></a>
      </div>
      <div :class="state.currentType === 'polyline' ? 'g-action-item selection' : 'g-action-item'" @click="draw('polyline', true)">
        <a><LineOutlined :rotate="135" class="fz20"/></a>
      </div>
      <div :class="state.currentType === 'polygon' ? 'g-action-item selection' : 'g-action-item'" @click="draw('polygon', true)">
        <a><BorderOutlined class="fz18" /></a>
      </div>
      <div v-if="mouseMode" class="g-action-item" @click="draw('off', false)">
        <a style="color: red;"><CloseOutlined /></a>
      </div>
    </div>
    <!-- 飞机OSD -->
    <div v-if="osdVisible.visible && !osdVisible.is_dock" v-drag-window class="osd-panel fz12">
      <div class="drag-title pl5 pr5 flex-align-center flex-row flex-justify-between" style="border-bottom: 1px solid #515151; height: 18%;">
        <span>{{ osdVisible.callsign }}</span>
        <span><a class="fz16" style="color: white;" @click="() => osdVisible.visible = false"><CloseOutlined /></a></span>
      </div>
      <div style="height: 82%;">
        <div class="flex-column flex-align-center flex-justify-center" style="margin-top: -5px; padding-top: 25px; float: left; width: 60px; background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div style="width: 90%;" class="flex-column flex-align-center flex-justify-center">
              <span><a-image :src="M30" :preview="false"/></span>
              <span>{{ osdVisible.model }}</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd">
          <a-row>
            <a-col span="16" :style="deviceInfo.device.mode_code === EModeCode.Disconnected ? 'color: red; font-weight: 700;': 'color: rgb(25,190,107)'">{{ EModeCode[deviceInfo.device.mode_code] }}</a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Signal strength">
                <span>HD</span>
                <span class="ml10">{{ deviceInfo.gateway?.transmission_signal_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RC Battery Level">
                <span><ThunderboltOutlined class="fz14"/></span>
                <span class="ml10">{{ deviceInfo.gateway && deviceInfo.gateway.capacity_percent !== str ? deviceInfo.gateway?.capacity_percent + ' %' : deviceInfo.gateway?.capacity_percent }}</span>
              </a-tooltip>
            </a-col>

            <a-col span="6">
              <a-tooltip title="Drone Battery Level">
                <span><ThunderboltOutlined class="fz14"/></span>
                <span class="ml10">{{ deviceInfo.device.battery.capacity_percent !== str ? deviceInfo.device.battery.capacity_percent + ' %' : deviceInfo.device.battery.capacity_percent }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-tooltip title="RTK Fixed">
              <a-col span="6" class="flex-row flex-align-center flex-justify-start">
                <span>Fixed</span>
                <span class="ml10 circle" :style="deviceInfo.device.position_state.is_fixed === 1 ? 'backgroud: rgb(25,190,107);' : ' background: red;'"></span>
              </a-col>
            </a-tooltip>
            <a-col span="6">
              <a-tooltip title="GPS">
                <span>GPS</span>
                <span class="ml10">{{ deviceInfo.device.position_state.gps_number }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RTK">
                <span><TrademarkOutlined class="fz14"/></span>
                <span class="ml10">{{ deviceInfo.device.position_state.rtk_number }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Flight Mode">
                <span><ControlOutlined class="fz16" /></span>
                <span class="ml10">{{ EGear[deviceInfo.device.gear] }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above sea level">
                <span>ASL</span>
                <span class="ml10">{{ deviceInfo.device.height === str ? str : deviceInfo.device.height.toFixed(2) + ' m'}}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above takeoff level">
                <span>ALT</span>
                <span class="ml10">{{ deviceInfo.device.elevation === str ? str : deviceInfo.device.elevation.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Distance to Home Point">
                <span>H</span>
                <span class="ml10">{{ deviceInfo.device.home_distance === str ? str : deviceInfo.device.home_distance.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Horizontal Speed">
                <span>H.S</span>
                <span class="ml10">{{ deviceInfo.device.horizontal_speed === str ? str : deviceInfo.device.horizontal_speed.toFixed(2) + ' m/s'}}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Vertical Speed">
                <span>V.S</span>
                <span class="ml10">{{ deviceInfo.device.vertical_speed === str ? str : deviceInfo.device.vertical_speed.toFixed(2) + ' m/s'}}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Wind Speed">
                <span></span>
                <span class="ml10">{{ deviceInfo.device.wind_speed === str ? str : (deviceInfo.device.wind_speed / 10).toFixed(2) + ' m/s'}}</span>
              </a-tooltip>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="battery-slide" v-if="deviceInfo.device.battery.remain_flight_time !== 0">
        <div style="background: #535759;" class="width-100"></div>
        <div class="capacity-percent" :style="{ width: deviceInfo.device.battery.capacity_percent + '%'}"></div>
        <div class="return-home" :style="{ width: deviceInfo.device.battery.return_home_power + '%'}"></div>
        <div class="landing" :style="{ width: deviceInfo.device.battery.landing_power + '%'}"></div>
        <div class="white-point" :style="{ left: deviceInfo.device.battery.landing_power + '%'}"></div>
        <div class="battery" :style="{ left: deviceInfo.device.battery.capacity_percent + '%' }">
          {{ Math.floor(deviceInfo.device.battery.remain_flight_time / 60) }}:
          {{ 10 > (deviceInfo.device.battery.remain_flight_time % 60) ? '0' : ''}}{{deviceInfo.device.battery.remain_flight_time % 60 }}
        </div>
      </div>
    </div>
    <!-- 机场OSD -->
    <div v-if="osdVisible.visible && osdVisible.is_dock"  v-drag-window class="osd-panel fz12">
      <div class="drag-title fz16 pl5 pr5 flex-align-center flex-row flex-justify-between" style="border-bottom: 1px solid #515151; height: 10%;">
        <span>{{ osdVisible.gateway_callsign }}</span>
      </div>
      <span><a style="color: white; position: absolute; top: 5px; right: 5px;" @click="() => osdVisible.visible = false"><CloseOutlined /></a></span>
      <!-- 机场 -->
      <div class ="flex-display" style="border-bottom: 1px solid #515151;">
        <div class="flex-column flex-align-stretch flex-justify-center" style="width: 100px; background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div class="flex-column  flex-align-center flex-justify-center" style="width: 90%;margin-left: 5%">
              <span><img src="http://82.157.253.64:8085/apis/file/onLineFile/a417c5d011d6dd15d283bb4c004895891704854315395" :style="{width:'70px',}"/></span>
              <span class="mt10">机场 <span class="fz10" :style="deviceInfo.dock.basic_osd?.mode_code === EDockModeCode.Disconnected ? 'color: red; font-weight: 700;': 'color: #1ABE6B'">
                {{ EDockModeCodeText[deviceInfo.dock.basic_osd?.mode_code] }}</span></span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd flex-1" style="flex: 1">
          <a-row type="flex" justify="space-around" align="middle">
            <a-col span="16" v-if="false"  :style="deviceInfo.dock.basic_osd?.mode_code === EDockModeCode.Disconnected ? 'color: red; font-weight: 700;': 'color: #1ABE6B'">
              {{ EDockModeCodeText[deviceInfo.dock.basic_osd?.mode_code] }}</a-col>

            <a-col span="18" :style="{textAlign:'right'}" class="height-50">
              <a-tooltip title="网络状态">
                  <span :style="qualityStyle">
                    <span v-if="deviceInfo.dock.basic_osd?.network_state?.type === NetworkStateTypeEnum.FOUR_G"><SignalFilled /></span>
                    <span v-else><GlobalOutlined /></span>
                  </span>
                <span class="ml10" >{{ deviceInfo.dock.basic_osd?.network_state?.rate }} kb/s</span>
              </a-tooltip>
            </a-col>
            <a-col span="6" :style="{textAlign:'right'}" class="height-100">
              <a-col  span="24">
                <a-button class="fz14" type="primary" :disabled="dockControlPanelVisible" size="small" @click="setDockControlPanelVisible(true)">
                  参数设置
                </a-button>
              </a-col>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="24" v-show="false">
              <a-tooltip title="累计运行时间">
                <span><HistoryOutlined /><span :style="{marginLeft:'5px'}">运行时长：</span></span>
                <span class="ml10">
                    <span v-if="deviceInfo.dock.work_osd?.acc_time >= 2592000"> {{ Math.floor(deviceInfo.dock.work_osd?.acc_time / 2592000) }}月 </span>
                    <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000) >= 86400"> {{ Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000) / 86400) }}天 </span>
                    <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400) >= 3600"> {{ Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400) / 3600) }}时 </span>
                    <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600) >= 60"> {{ Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600) / 60) }}分 </span>
                    <span>{{ Math.floor(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600 % 60) }} 秒</span>
                  </span>
              </a-tooltip>
            </a-col>
            <a-col span="12" v-show="false">
              <a-tooltip title="激活时间">
                <span><FieldTimeOutlined /><span :style="{marginLeft:'5px'}">激活时间：</span></span>
                <span class="ml10">{{ new Date((deviceInfo.dock.work_osd?.activation_time ?? 0) * 1000).toLocaleString() }}
                  </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row justify="center" :style="{marginTop:'10px'}">
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="总执行任务次数">
                <a-col :span="24">
                  <span style="font-size: 16px"><CarryOutOutlined :style="{fontSize:'16px'}" /> {{ deviceInfo.dock.work_osd?.job_number }} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">作业次数</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="待同步文件数量">
                <a-col :span="24">
                  <span style="font-size: 16px"><CloudUploadOutlined :style="{fontSize:'16px'}" /> {{ deviceInfo.dock.link_osd?.media_file_detail?.remain_upload }} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">待同步</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="环境风速">
                <a-col :span="24">
                  <span style="font-size: 16px"><span :style="{fontSize:'14px'}" ></span> {{ (deviceInfo.dock.basic_osd?.wind_speed ?? str) + ' m/s'}} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">环境风速</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="环境雨量">
                <a-col :span="24">
                  <span style="font-size: 16px"><span :style="{fontSize:'16px'}" >🌧</span> {{ RainfallEnumText[deviceInfo.dock.basic_osd?.rainfall] }} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">环境雨量</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <!--                 <a-col span="6">-->
            <!--                <a-tooltip>-->
            <!--                  <template #title>-->
            <!--                    <p>全部: {{ deviceInfo.dock.basic_osd?.storage?.total }}</p>-->
            <!--                    <p>已使用: {{ deviceInfo.dock.basic_osd?.storage?.used  }}</p>-->
            <!--                  </template>-->
            <!--                  <span><FolderOpenOutlined /></span>-->
            <!--                  <span class="ml10" v-if="deviceInfo.dock.basic_osd?.storage?.total > 0">-->
            <!--                    <a-progress type="circle" :width="20" :percent="deviceInfo.dock.basic_osd?.storage?.used * 100/ deviceInfo.dock.basic_osd?.storage?.total"-->
            <!--                      :strokeWidth="20" :showInfo="false" :strokeColor="deviceInfo.dock.basic_osd?.storage?.used * 100 / deviceInfo.dock.basic_osd?.storage?.total > 80 ? 'red' : '#00ee8b' "/>-->
            <!--                  </span>-->
            <!--                </a-tooltip>-->
            <!--              </a-col>-->
          </a-row>

          <a-row :style="{marginTop:'10px',marginBottom:'20px'}">
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="环境湿度">
                <a-col :span="24">
                  <span style="font-size: 16px">{{ deviceInfo.dock.basic_osd?.environment_temperature }} <span :style="{fontSize:'16px'}" >°C</span> </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">环境温度</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="舱内温度">
                <a-col :span="24">
                  <span style="font-size: 16px">{{ deviceInfo.dock.basic_osd?.temperature }}<span :style="{fontSize:'16px'}" >°C</span>  </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">舱内温度</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="环境湿度">
                <a-col :span="24">
                  <span style="font-size: 16px"><span :style="{fontSize:'16px'}" >💦</span> {{ deviceInfo.dock.basic_osd?.humidity }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">环境湿度</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="空调状态">
                <a-col :span="24">
                  <span style="font-size: 16px"><ProjectOutlined :style="{fontSize:'16px'}" /> {{  ConditionerEnumText[deviceInfo.dock.basic_osd?.air_conditioner?.air_conditioner_state]   }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">空调状态</span>
                </a-col>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row :style="{marginTop:'10px',marginBottom:'20px'}">
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="上一次保养类型">
                <a-col :span="24">
                  <span style="font-size: 16px">{{ deviceInfo.dock.work_osd?.maintain_status?.last_maintain_type== undefined?'无': deviceInfo.dock.work_osd?.maintain_status?.last_maintain_type== undefined}}  </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">上次保养类型</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="上一次保养时间">
                <a-col :span="24">
                  <span style="font-size: 16px">{{ deviceInfo.dock.work_osd?.maintain_status?.last_maintain_time== undefined?'无':deviceInfo.dock.work_osd?.maintain_status?.last_maintain_time }}  </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">上次保养时间</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="8" :style="{textAlign:'center'}">
              <a-tooltip title="上一次保养时作业架次">
                <a-col :span="24">
                  <span style="font-size: 16px"> {{ deviceInfo.dock.work_osd?.maintain_status?.last_maintain_work_sorties == undefined?'无': deviceInfo.dock.work_osd?.maintain_status?.last_maintain_work_sorties}}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">上次保养时作业架次</span>
                </a-col>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row class="p5" v-if="false">
            <a-col span="24">
              <a-button type="primary" :disabled="dockControlPanelVisible" size="small" @click="setDockControlPanelVisible(true)">
                远程控制
              </a-button>
            </a-col>
          </a-row>
          <!-- 机场控制面板 -->
          <DockControlPanel v-if="dockControlPanelVisible" :sn="osdVisible.gateway_sn"  :deviceInfo="deviceInfo" @close-control-panel="onCloseControlPanel">
          </DockControlPanel>
        </div>
      </div>
      <!--  飞机-->
      <div class ="flex-display">
        <div class="flex-column flex-align-stretch flex-justify-center" style="width: 100px;  background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div style="width: 90%;margin-left: 5%" class="flex-column flex-align-center flex-justify-center">
              <span><a-image :src="M30" :preview="false" :style="{width:'70px'}"/></span>
              <span>M30T  <span class="fz10" :style="!deviceInfo.device || deviceInfo.device?.mode_code === EModeCode.Disconnected ? 'color: red; font-weight: 700;': 'color: rgb(25,190,107)'">
                {{ !deviceInfo.device ? EModeCodeText[EModeCode.Disconnected] : EModeCodeText[deviceInfo.device?.mode_code] }}</span></span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd flex-1">
          <a-row type="flex" justify="space-around" align="middle">
            <a-col span="16" v-if="false"  :style="deviceInfo.dock.basic_osd?.mode_code === EDockModeCode.Disconnected ? 'color: red; font-weight: 700;': 'color: #1ABE6B'">
              {{ EDockModeCodeText[deviceInfo.dock.basic_osd?.mode_code] }}</a-col>
            <a-col span="6">

            </a-col>
            <a-col span="6" :style="{textAlign:'right'}" class="height-50">
              <a-tooltip title="网络状态">
                  <span :style="qualityStyle">
                     <ArrowUpOutlined style="font-size: 14px; " />
                  </span>
                <span class="ml10" >{{ deviceInfo.dock.link_osd?.sdr?.up_quality }} kb/s</span>
              </a-tooltip>
            </a-col>
            <a-col span="6" :style="{textAlign:'right'}" class="height-50">
              <a-tooltip title="网络状态">
                  <span :style="qualityStyle">
                     <ArrowDownOutlined style="font-size: 14px; " />
                  </span>
                <span class="ml10" >{{ deviceInfo.dock.link_osd?.sdr?.down_quality }} kb/s</span>
              </a-tooltip>
            </a-col>
            <a-col span="6" :style="{textAlign:'right'}" class="height-100">
              <a-col  span="24">
                <a-button class="fz14" type="primary" :disabled="droneControlPanelVisible" size="small" @click="setDroneControlPanelVisible(true)">
                  远程控制
                </a-button>
              </a-col>
            </a-col>
          </a-row>
          <a-row :style="{marginTop:'10px'}">

            <a-col :span="6" :style="{textAlign:'center'}" v-if="false">
              <a-tooltip title="网络上行质量">
                <a-col :span="24">
                  <span style="font-size: 16px"><SignalFilled style="font-size: 16px"/><ArrowUpOutlined style="font-size: 9px; vertical-align: top;" /> {{ deviceInfo.dock.link_osd?.sdr?.up_quality }} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">网络上行</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}" v-if="false">
              <a-tooltip title="网络下行质量">
                <a-col :span="24">
                  <span style="font-size: 16px"><SignalFilled style="font-size: 16px"/><ArrowDownOutlined style="font-size: 9px; vertical-align: top;" /> {{ deviceInfo.dock.link_osd?.sdr?.down_quality }} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">网络下行</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="飞机电量">
                <a-col :span="24">
                  <span style="font-size: 16px"><ThunderboltOutlined style="font-size: 16px"/>{{ deviceInfo.device && deviceInfo.device.battery.capacity_percent !== str ? deviceInfo.device?.battery.capacity_percent + ' %' : str }} </span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">飞机电量</span>
                </a-col>
              </a-tooltip>
            </a-col>

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="GPS">
                <a-col :span="24">
                  <span style="font-size: 16px"><span style="font-size: 14px"></span>{{ deviceInfo.device ? deviceInfo.device.position_state.gps_number : str }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">GPS</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="海拔高度">
                <a-col :span="24">
                  <span style="font-size: 16px"><span style="font-size: 14px"></span>{{ !deviceInfo.device || deviceInfo.device.elevation === str ? str : deviceInfo.device?.elevation.toFixed(2) + ' m' }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">相对地面高度</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="返航距离">
                <a-col :span="24">
                  <span style="font-size: 16px"><span style="font-size: 14px"></span>{{  !deviceInfo.device || deviceInfo.device.home_distance === str ? str : deviceInfo.device?.home_distance.toFixed(2) + ' m'  }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">返航距离</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <!--              <a-col span="6">-->
            <!--                <a-tooltip>-->
            <!--                  <template #title>-->
            <!--                    <p>total: {{ deviceInfo.device?.storage?.total }}</p>-->
            <!--                    <p>used: {{ deviceInfo.device?.storage?.used  }}</p>-->
            <!--                  </template>-->
            <!--                  <span><FolderOpenOutlined /></span>-->
            <!--                  <span class="ml10" v-if="deviceInfo.device?.storage?.total > 0">-->
            <!--                    <a-progress type="circle" :width="20" :percent="deviceInfo.device?.storage?.used * 100/ deviceInfo.device?.storage?.total"-->
            <!--                      :strokeWidth="20" :showInfo="false" :strokeColor="deviceInfo.device?.storage?.used * 100 / deviceInfo.device?.storage?.total > 80 ? 'red' : '#00ee8b' "/>-->
            <!--                  </span>-->
            <!--                </a-tooltip>-->
            <!--              </a-col>-->
            <!--                -->
            <!--                -->
          </a-row>
          <a-row :style="{marginTop:'10px',marginBottom:'20px'}">

            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="迎风速度">
                <a-col :span="24">
                  <span style="font-size: 16px"><span style="font-size: 14px"></span>{{   !deviceInfo.device || deviceInfo.device?.horizontal_speed === str ? str : deviceInfo.device?.horizontal_speed.toFixed(2) + ' m/s'  }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">迎风速度</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="垂直速度">
                <a-col :span="24">
                  <span style="font-size: 16px"><span style="font-size: 14px"></span>{{   !deviceInfo.device || deviceInfo.device.vertical_speed === str ? str : deviceInfo.device?.vertical_speed.toFixed(2) + ' m/s'  }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">垂直速度</span>
                </a-col>
              </a-tooltip>
            </a-col>
            <a-col :span="6" :style="{textAlign:'center'}">
              <a-tooltip title="循环次数">
                <a-col :span="24">
                  <span style="font-size: 16px"><span style="font-size: 14px"></span>{{   !deviceInfo.device || deviceInfo.device.battery.loop_times === str ? str : deviceInfo.device?.battery.loop_times + ' 次'  }}</span>
                </a-col>
                <a-col :span="24">
                  <span style="font-size: 12px;color: #8A8A8A">循环次数</span>
                </a-col>
              </a-tooltip>
            </a-col>

          </a-row>
          <a-row>
            <a-tooltip title="RTK Fixed" v-if="false">
              <a-col span="6" class="flex-row flex-align-center flex-justify-start">
                <span>Fixed</span>
                <span class="ml10 circle" :style="deviceInfo.device?.position_state.is_fixed === 1 ? 'backgroud: rgb(25,190,107);' : ' background: red;'"></span>
              </a-col>
            </a-tooltip>
            <a-col span="6">
              <a-tooltip title="GPS" v-if="false">
                <span>GPS</span>
                <span class="ml10">{{ deviceInfo.device ? deviceInfo.device.position_state.gps_number : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RTK" v-if="false">
                <span><TrademarkOutlined class="fz14"/></span>
                <span class="ml10">{{ deviceInfo.device ? deviceInfo.device.position_state.rtk_number : str }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row v-if="false">
            <a-col span="6" v-if="false">
              <a-tooltip title="Flight Mode">
                <span><ControlOutlined class="fz16" /></span>
                <span class="ml10">{{ deviceInfo.device ? EGear[deviceInfo.device?.gear] : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6" v-if="false">
              <a-tooltip title="Altitude above sea level">
                <span>ASL</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.height === str ? str : deviceInfo.device?.height.toFixed(2) + ' m'}}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6" v-if="false">
              <a-tooltip title="Distance to Home Point">
                <span style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 15px; text-align: center;  display: block; float: left;" >H</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.home_distance === str ? str : deviceInfo.device?.home_distance.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row v-if="false">
            <a-col span="6">
              <a-tooltip title="Horizontal Speed">
                <span>H.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device?.horizontal_speed === str ? str : deviceInfo.device?.horizontal_speed.toFixed(2) + ' m/s'}}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Vertical Speed">
                <span>V.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.vertical_speed === str ? str : deviceInfo.device?.vertical_speed.toFixed(2) + ' m/s'}}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Wind Speed">
                <span>W.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.wind_speed === str ? str : (deviceInfo.device?.wind_speed / 10).toFixed(2) + ' m/s'}}</span>
              </a-tooltip>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="battery-slide" v-if="deviceInfo.device && deviceInfo.device.battery.remain_flight_time !== 0" style="border: 1px solid red">
        <div style="background: #535759;" class="width-100"></div>
        <div class="capacity-percent" :style="{ width: deviceInfo.device.battery.capacity_percent + '%'}"></div>
        <div class="return-home" :style="{ width: deviceInfo.device.battery.return_home_power + '%'}"></div>
        <div class="landing" :style="{ width: deviceInfo.device.battery.landing_power + '%'}"></div>
        <div class="white-point" :style="{ left: deviceInfo.device.battery.landing_power + '%'}"></div>
        <div class="battery" :style="{ left: deviceInfo.device.battery.capacity_percent + '%' }">
          {{ Math.floor(deviceInfo.device.battery.remain_flight_time / 60) }}:
          {{ 10 > (deviceInfo.device.battery.remain_flight_time % 60) ? '0' : ''}}{{deviceInfo.device.battery.remain_flight_time % 60 }}
        </div>
      </div>
      <!-- 飞行指令 -->

    </div>

    <div class="osd-panel1" v-if="this.$root.$route.name == ERouterName.TSA">
      <DroneControlPanel v-if="droneControlPanelVisible" :sn="osdVisible.gateway_sn" :deviceInfo="deviceInfo" :payloads="osdVisible.payloads" @close-drone-control-panel="onCloseDroneControlPanel" ></DroneControlPanel>
    </div>
    <!-- liveview -->
    <div class="liveview" v-if="livestreamOthersVisible" v-drag-window  >
      <div style="height: 40px; width: 100%" class="drag-title"></div>
      <a style="position: absolute; right: 10px; top: 10px; font-size: 16px; color: white;" @click="closeLivestreamOthers"><CloseOutlined /></a>
      <LivestreamOthers />
    </div>
    <div class="liveview" v-if="livestreamAgoraVisible" v-drag-window  >
      <div style="height: 40px; width: 100%" class="drag-title"></div>
      <a style="position: absolute; right: 10px; top: 10px; font-size: 16px; color: white;" @click="closeLivestreamAgora"><CloseOutlined /></a>
      <LivestreamAgora />
    </div>
    <div class="liveview-big" v-if="false" v-drag-window  >
      <div style="height: 40px; width: 100%" class="drag-title"></div>
      <a style="position: absolute; right: 10px; top: 10px; font-size: 16px; color: white;" @click="closeLivestreamOthers"><CloseOutlined /></a>
      <LivestreamOthersSm />
    </div>
  </div>

  <div style="margin-left: 0px;margin-top: 10px;margin-right: 0px;width: 25%;height:5%;position: absolute;z-index: 9999999999999;right: 15px;bottom: 25%" v-show="this.$root.$route.name == ERouterName.TSA && deviceVideoShowState">
    <a-row>

      <a-col :span="8">
        <a-select
            class="ml10"
            style="width: 120px;background: #232323"
            placeholder="选择镜头"
            v-model:value="cameraSelected"
        >
          <a-select-option
              v-for="item in cameraList"
              :key="item.value"
              :value="item.value"
              @click="onCameraSelect(item)"
          >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-col>
      <a-col :span="8">
        <a-select

            class="ml10"
            style="width: 120px;background: #232323"
            placeholder="选择类型"
            v-model:value="lensSelected"
        >
          <a-select-option
              v-for="item in lensList"
              :key="item"
              :value="item"
              @click="onLensSelect(item)"
          >{{ item==="wide"?"广角":item==="ir"?"红外":item=="zoom"?"变焦":item=="normal"?"默认":"未知" }}</a-select-option
          >
        </a-select>
      </a-col>
      <a-col :span="8" :style="{marginRight:'0px'}">

        <a-select
            class="ml10"
            style="width: 120px;background: #232323;right: 0px"
            placeholder="选择清晰度"
            @select="onUpdateQuality"
            v-model:value="claritySelected"
        >
          <a-select-option
              v-for="item in clarityList"
              :key="item.value"
              :value="item.value"
          >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-col>
    </a-row>
  </div>

  <!--机场切换按钮    -->
  <div style="background-color: white;margin-left: 10px;margin-top: 10px;width: 25px;height:25px;position: absolute;z-index: 200;left: 1%;bottom: 2%" v-show="this.$root.$route.name == ERouterName.TSA && dockVideoShowState">
    <img src="https://www.shadyside.cn/static/fullScreen.png" preview="false" @click="changeMapAndVideo" style="width: 25px;height: 25px"/>
  </div>

  <div style="background-color: white;margin-left: 10px;margin-top: 10px;width: 25px;height:25px;position: absolute;z-index: 200;right: 24%;bottom: 23%" v-show="this.$root.$route.name == ERouterName.TSA && deviceVideoShowState">
    <img src="https://www.shadyside.cn/static/fullScreen.png" preview="false" @click="changeMapAndVideo" style="width: 25px;height: 25px"/>
  </div>

  <div class="liveview-big" v-show="this.$root.$route.name == ERouterName.TSA && dockVideoShowState" :style="state.mapType?{width:'100%',height:'100%',right:'0px',bottom:'0px',zIndex: 99}:{width:'25%',height:'25%',zIndex:'99',left:'15px',bottom:'10px',border:'1px solid white'}">

    <video
        :style="{ width: '100%', height: '100%',objectFit:'fill',float:'top' }"
        id="smallVideo"
        ref="videowebrtc"
        autoplay
        muted
    ></video>
  </div>

  <div class="liveview-big" v-show="this.$root.$route.name == ERouterName.TSA && deviceVideoShowState  " :style="state.mapType?{width:'100%',height:'100%',right:'0px',bottom:'0px',zIndex: 99}:{width:'25%',height:'25%',zIndex:'99',right:'15px',bottom:'10px',border:'1px solid white'}">

    <video
        :style="{ width: '100%', height: '100%',objectFit:'fill',float:'top' }"
        id="smallVideo1"
        ref="videowebrtc"
        autoplay
        muted
    ></video>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { generateLineContent, generatePointContent, generatePolyContent } from '../utils/map-layer-utils'
import { postElementsReq } from '/@/api/layer'
import { MapDoodleType, MapElementEnum } from '/@/constants/map'
import { useGMapManage } from '/@/hooks/use-g-map'
import { useGMapCover } from '/@/hooks/use-g-map-cover'
import { useMouseTool } from '/@/hooks/use-mouse-tool'
import { getApp, getRoot } from '/@/root'
import store, { useMyStore } from '/@/store'
import { GeojsonCoordinate } from '/@/types/map'
import { MapDoodleEnum } from '/@/types/map-enum'
import { PostElementsBody } from '/@/types/mapLayer'
import { uuidv4 } from '/@/utils/uuid'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'
import { deviceTsaUpdate } from '/@/hooks/use-g-map-tsa'
import {
  ConditionerEnumText,
  DeviceOsd,
  DeviceStatus,
  DockOsd,
  DroneInDockEnum,
  EDockModeCode, EDockModeCodeText,
  EGear,
  EModeCode, EModeCodeText,
  GatewayOsd,
  NetworkStateQualityEnum,
  NetworkStateTypeEnum,
  RainfallEnum, RainfallEnumText
} from '/@/types/device'
import pin from '/@/assets/icons/pin-2d8cf0.svg'
import M30 from '/@/assets/icons/m30.png'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  BorderOutlined,
  CarryOutOutlined,
  CloseOutlined,
  CloudFilled,
  CloudOutlined,
  CloudUploadOutlined,
  ProjectOutlined,
  ControlOutlined,
  FieldTimeOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  HistoryOutlined,
  LineOutlined,
  RobotFilled,
  RocketOutlined,
  SignalFilled,
  ThunderboltOutlined,
  TrademarkOutlined
} from '@ant-design/icons-vue'
import { EDeviceTypeName, ERouterName } from '../types'
import DockControlPanel from './g-map/DockControlPanel.vue'
import { useDockControl } from './g-map/use-dock-control'
import { useDroneControl } from './g-map/use-drone-control'
import DroneControlPanel from './g-map/DroneControlPanel.vue'
import { useConnectMqtt } from './g-map/use-connect-mqtt'
import LivestreamOthers from './livestream-others.vue'
import LivestreamAgora from './livestream-agora.vue'
import { getWayLineFileById } from '/@/api/wayline'
import router from '/@/router'
import mpegts from 'mpegts.js'
import { SelectProps } from 'ant-design-vue/es/vc-select'
import {
  changeLivestreamLens,
  getLiveCapacity,
  getLiveCapacityNew,
  setLivestreamQuality,
  startLivestream,
  startLivestreamAi
} from '/@/api/manage'
import { message } from 'ant-design-vue'
import { CURRENT_CONFIG as config } from '/@/api/http/config'

export default defineComponent({
  computed: {
    ConditionerEnumText () {
      return ConditionerEnumText
    },
    RainfallEnumText () {
      return RainfallEnumText
    },
    EModeCodeText () {
      return EModeCodeText
    },
    EDockModeCodeText () {
      return EDockModeCodeText
    },
    ERouterName () {
      return ERouterName
    }
  },
  methods: {
    router () {
      return router
    }
  },
  components: {
    BorderOutlined,
    LineOutlined,
    CloseOutlined,
    ControlOutlined,
    TrademarkOutlined,
    ThunderboltOutlined,
    SignalFilled,
    GlobalOutlined,
    HistoryOutlined,
    CloudUploadOutlined,
    ProjectOutlined,
    FieldTimeOutlined,
    CloudOutlined,
    CloudFilled,
    FolderOpenOutlined,
    RobotFilled,
    ArrowUpOutlined,
    ArrowDownOutlined,
    DockControlPanel,
    DroneControlPanel,
    CarryOutOutlined,
    RocketOutlined,
    LivestreamOthers,
    LivestreamAgora
  },
  name: 'GMap',
  props: {},
  setup () {
    interface SelectOption {
      value: any,
      label: string,
      more?: any
    }

    interface FlvList {
      value: any,
      sn: string,
      name:string
    }

    const useMouseToolHook = useMouseTool()
    const useGMapManageHook = useGMapManage()
    const deviceTsaUpdateHook = deviceTsaUpdate()

    const droneSelected = ref()
    const cameraSelected = ref()
    const videoSelected = ref()
    const claritySelected = ref()
    const livestreamSource = ref()
    const itemVideoId = ref()
    const droneList = ref()
    const cameraList = ref()
    const videoId = ref()
    const flvIndexList = ref()
    const nonSwitchable = 'normal'

    const dockVideoShowState = ref<boolean>(false)
    const deviceVideoShowState = ref<boolean>(false)
    const clarityList: SelectOption[] = [
      {
        value: 0,
        label: '自适应'
      },
      {
        value: 1,
        label: '流畅'
      },
      {
        value: 2,
        label: '标清'
      },
      {
        value: 3,
        label: '高清'
      },
      {
        value: 4,
        label: '超清'
      }
    ]
    const videoList = ref()
    const lensList = ref<string[]>([])
    const lensSelected = ref<String>()
    const isDockLive = ref(false)
    const videowebrtc = ref(null)
    const deviceLiveState = ref<boolean>(false)
    const liveState = ref<boolean>(false)
    const smallPlayer = ref<any>()
    const onDroneSelect = (val: SelectOption) => {
      droneSelected.value = val.value
      const temp: Array<SelectOption> = []
      cameraList.value = []
      cameraSelected.value = undefined
      videoSelected.value = undefined
      videoList.value = []
      lensList.value = []
      if (!val.more) {
        return
      }
      val.more.forEach((ele: any) => {
        if (ele.name !== 'DJI Dock Camera') {
          temp.push({ label: ele.name === 'DJI Dock Camera' ? '机场监控' : ele.name, value: ele.index, more: ele.videos_list })
        }
      })
      console.log('镜头数据：' + JSON.stringify(temp))
      cameraList.value = temp
    }
    const onCameraSelect = (val: SelectOption) => {
      cameraSelected.value = val.value
      const result: Array<SelectOption> = []
      videoSelected.value = undefined
      videoList.value = []
      lensList.value = []
      if (!val.more) {
        return
      }

      val.more.forEach((ele: any) => {
        result.push({ label: ele.type, value: ele.index, more: ele.switch_video_types })
      })
      videoList.value = result
      if (videoList.value.length === 0) {
        return
      }
      const firstVideo: SelectOption = videoList.value[0]
      videoSelected.value = firstVideo.value
      lensList.value = firstVideo.more
      lensSelected.value = firstVideo.label
      isDockLive.value = lensList.value?.length > 0

      flvIndexList.value.forEach((item:any) => {
        if (item.name === val.label) {
          onStartVideo('smallVideo1', store.state.nowClickDevice?.sn, item.value)
        }
      })
      console.log('flvIndexList:' + JSON.stringify(flvIndexList.value))
      //
    }
    const onVideoSelect = (val: SelectOption) => {
      videoSelected.value = val.value
      lensList.value = val.more
      lensSelected.value = val.label
    }

    const onLensSelect = (val: string) => {
      lensSelected.value = val
      onSwitch()
    }
    // 加载航线

    const root = getRoot()

    const mouseMode = ref(false)
    const store = useMyStore()
    const state = reactive({
      currentType: '',
      coverIndex: 0,
      mapType: false
    })
    const wayLineData = reactive({
      wayLineName: '',
      wayLineDis: 0,
      wayLineNum: 0,
      wayLineTime: 0,
      wayLinePhotos: 0
    })
    const str: string = '--'
    const deviceInfo = reactive({
      gateway: {
        capacity_percent: str,
        transmission_signal_quality: str,
      } as GatewayOsd,
      dock: {

      } as DockOsd,
      device: {
        gear: -1,
        mode_code: EModeCode.Disconnected,
        height: str,
        home_distance: str,
        horizontal_speed: str,
        vertical_speed: str,
        wind_speed: str,
        wind_direction: str,
        elevation: str,
        position_state: {
          gps_number: str,
          is_fixed: 0,
          rtk_number: str
        },
        battery: {
          capacity_percent: str,
          landing_power: str,
          remain_flight_time: 0,
          return_home_power: str,
        },
        latitude: 0,
        longitude: 0,
      } as DeviceOsd
    })
    const shareId = computed(() => {
      return store.state.layerBaseInfo.share
    })
    const defaultId = computed(() => {
      return store.state.layerBaseInfo.default
    })
    const drawVisible = computed(() => {
      return store.state.drawVisible
    })
    const livestreamOthersVisible = computed(() => {
      return store.state.livestreamOthersVisible
    })
    const livestreamAgoraVisible = computed(() => {
      return store.state.livestreamAgoraVisible
    })
    const osdVisible = computed(() => {
      return store.state.osdVisible
    })
    const qualityStyle = computed(() => {
      if (deviceInfo.dock.basic_osd?.network_state?.type === NetworkStateTypeEnum.ETHERNET ||
          (deviceInfo.dock.basic_osd?.network_state?.quality || 0) > NetworkStateQualityEnum.FAIR) {
        return 'color: #00ee8b'
      }
      if ((deviceInfo.dock.basic_osd?.network_state?.quality || 0) === NetworkStateQualityEnum.FAIR) {
        return 'color: yellow'
      }
      return 'color: red'
    })
    watch(() => store.state.deviceStatusEvent,
      data => {
        if (Object.keys(data.deviceOnline).length !== 0) {
          deviceTsaUpdateHook.initMarker(data.deviceOnline.domain, data.deviceOnline.device_callsign, data.deviceOnline.sn)
          store.state.deviceStatusEvent.deviceOnline = {} as DeviceStatus
        }
        if (Object.keys(data.deviceOffline).length !== 0) {
          deviceTsaUpdateHook.removeMarker(data.deviceOffline.sn)
          if ((data.deviceOffline.sn === osdVisible.value.sn) || (osdVisible.value.is_dock && data.deviceOffline.sn === osdVisible.value.gateway_sn)) {
            osdVisible.value.visible = false
            store.commit('SET_OSD_VISIBLE_INFO', osdVisible)
          }
          store.state.deviceStatusEvent.deviceOffline = {}
        }
      },
      {
        deep: true
      }
    )

    watch(() => store.state.deviceState, data => {
      if (data.currentType === EDeviceTypeName.Gateway && data.gatewayInfo[data.currentSn]) {
        deviceTsaUpdateHook.moveTo(data.currentSn, data.gatewayInfo[data.currentSn].longitude, data.gatewayInfo[data.currentSn].latitude)
        if (osdVisible.value.visible && osdVisible.value.gateway_sn !== '') {
          deviceInfo.gateway = data.gatewayInfo[osdVisible.value.gateway_sn]
        }
      }
      if (data.currentType === EDeviceTypeName.Aircraft && data.deviceInfo[data.currentSn]) {
        deviceTsaUpdateHook.moveTo(data.currentSn, data.deviceInfo[data.currentSn].longitude, data.deviceInfo[data.currentSn].latitude)
        if (osdVisible.value.visible && osdVisible.value.sn !== '') {
          deviceInfo.device = data.deviceInfo[osdVisible.value.sn]
        }
      }
      if (data.currentType === EDeviceTypeName.Dock && data.dockInfo[data.currentSn]) {
        // console.log('机场数据：' + JSON.stringify(data))
        // console.log('经纬度：' + data.dockInfo[data.currentSn].basic_osd?.longitude + '---' + data.dockInfo[data.currentSn].basic_osd?.latitude)
        deviceTsaUpdateHook.initMarker(EDeviceTypeName.Dock, EDeviceTypeName[EDeviceTypeName.Dock], data.currentSn, data.dockInfo[data.currentSn].basic_osd?.longitude, data.dockInfo[data.currentSn].basic_osd?.latitude)
        if (osdVisible.value.visible && osdVisible.value.is_dock && osdVisible.value.gateway_sn !== '') {
          deviceInfo.dock = data.dockInfo[osdVisible.value.gateway_sn]
          deviceInfo.device = data.deviceInfo[deviceInfo.dock.basic_osd.sub_device?.device_sn ?? osdVisible.value.sn]
        }
      }
    }, {
      deep: true
    })

    watch(
      () => store.state.wsEvent,
      newData => {
        const useGMapCoverHook = useGMapCover()
        const event = newData
        let exist = false
        if (Object.keys(event.mapElementCreat).length !== 0) {
          console.log(event.mapElementCreat)
          const ele = event.mapElementCreat
          store.state.Layers.forEach(layer => {
            layer.elements.forEach(e => {
              if (e.id === ele.id) {
                exist = true
                console.log('true')
              }
            })
          })
          if (exist === false) {
            setLayers({
              id: ele.id,
              name: ele.name,
              resource: ele.resource
            })

            updateCoordinates('wgs84-gcj02', ele)
            const data = { id: ele.id, name: ele.name }
            if (MapElementEnum.PIN === ele.resource?.type) {
              useGMapCoverHook.init2DPin(
                ele.name,
                ele.resource.content.geometry.coordinates,
                ele.resource.content.properties.color,
                data
              )
            } else if (MapElementEnum.LINE === ele.resource?.type) {
              useGMapCoverHook.initPolyline(
                ele.name,
                ele.resource.content.geometry.coordinates,
                ele.resource.content.properties.color,
                data)
            } else if (MapElementEnum.POLY === ele.resource?.type) {
              useGMapCoverHook.initPolygon(
                ele.name,
                ele.resource.content.geometry.coordinates,
                ele.resource.content.properties.color,
                data)
            }
          }

          store.state.wsEvent.mapElementCreat = {}
        }
        if (Object.keys(event.mapElementUpdate).length !== 0) {
          console.log(event.mapElementUpdate)
          console.log('该功能还未实现，请开发商自己增加')
          store.state.wsEvent.mapElementUpdate = {}
        }
        if (Object.keys(event.mapElementDelete).length !== 0) {
          console.log(event.mapElementDelete)
          console.log('该功能还未实现，请开发商自己增加')
          store.state.wsEvent.mapElementDelete = {}
        }
      },
      {
        deep: true
      }
    )

    function draw (type: MapDoodleType, bool: boolean) {
      state.currentType = type
      useMouseToolHook.mouseTool(type, getDrawCallback)
      mouseMode.value = bool
    }

    // dock 控制面板
    const {
      dockControlPanelVisible,
      setDockControlPanelVisible,
      onCloseControlPanel,
    } = useDockControl()

    const {
      droneControlPanelVisible,
      setDroneControlPanelVisible,
      onCloseDroneControlPanel,
    } = useDroneControl()

    // drone 控制面板

    // 连接或断开drc
    useConnectMqtt()

    onMounted(() => {
      const app = getApp()
      useGMapManageHook.globalPropertiesConfig(app)
      showLiveData()
    })

    function getDrawCallback ({ obj }) {
      switch (state.currentType) {
        case MapDoodleEnum.PIN:
          postPinPositionResource(obj)
          break
        case MapDoodleEnum.POLYLINE:
          postPolylineResource(obj)
          break
        case MapDoodleEnum.POLYGON:
          postPolygonResource(obj)
          break
        default:
          break
      }
    }
    async function postPinPositionResource (obj) {
      const req = getPinPositionResource(obj)
      setLayers(req)
      const coordinates = req.resource.content.geometry.coordinates
      updateCoordinates('gcj02-wgs84', req);
      (req.resource.content.geometry.coordinates as GeojsonCoordinate).push((coordinates as GeojsonCoordinate)[2])
      const result = await postElementsReq(shareId.value, req)
      obj.setExtData({ id: req.id, name: req.name })
      store.state.coverList.push(obj)
      // console.log(store.state.coverList)
    }
    async function postPolylineResource (obj) {
      const req = getPolylineResource(obj)
      setLayers(req)
      updateCoordinates('gcj02-wgs84', req)
      const result = await postElementsReq(shareId.value, req)
      obj.setExtData({ id: req.id, name: req.name })
      store.state.coverList.push(obj)
      // console.log(store.state.coverList)
    }
    async function postPolygonResource (obj) {
      const req = getPoygonResource(obj)
      setLayers(req)
      updateCoordinates('gcj02-wgs84', req)
      const result = await postElementsReq(shareId.value, req)
      obj.setExtData({ id: req.id, name: req.name })
      store.state.coverList.push(obj)
      // console.log(store.state.coverList)
    }

    function getPinPositionResource (obj) {
      const position = obj.getPosition()
      const resource = generatePointContent(position)
      const name = obj._originOpts.title
      const id = uuidv4()
      return {
        id,
        name,
        resource
      }
    }
    function getPolylineResource (obj) {
      const path = obj.getPath()
      const resource = generateLineContent(path)
      const { name, id } = getBaseInfo(obj._opts)
      return {
        id,
        name,
        resource
      }
    }
    function getPoygonResource (obj) {
      const path = obj.getPath()
      const resource = generatePolyContent(path)
      const { name, id } = getBaseInfo(obj._opts)
      return {
        id,
        name,
        resource
      }
    }
    function getBaseInfo (obj) {
      const name = obj.title
      const id = uuidv4()
      return { name, id }
    }
    function setLayers (resource: PostElementsBody) {
      const layers = store.state.Layers
      const layer = layers.find(item => item.id.includes(shareId.value))
      // layer.id = 'private_layer' + uuidv4()
      // layer?.elements.push(resource)
      if (layer?.elements) {
        ;(layer?.elements as any[]).push(resource)
      }
      console.log('layers', layers)
      store.commit('SET_LAYER_INFO', layers)
    }
    function closeLivestreamOthers () {
      store.commit('SET_LIVESTREAM_OTHERS_VISIBLE', false)
    }
    function closeLivestreamAgora () {
      store.commit('SET_LIVESTREAM_AGORA_VISIBLE', false)
    }
    function updateCoordinates (transformType: string, element: any) {
      const geoType = element.resource?.content.geometry.type
      const type = element.resource?.type as number
      if (element.resource) {
        if (MapElementEnum.PIN === type) {
          const coordinates = element.resource?.content.geometry
            .coordinates as GeojsonCoordinate
          if (transformType === 'wgs84-gcj02') {
            const transResult = wgs84togcj02(
              coordinates[0],
              coordinates[1]
            ) as GeojsonCoordinate
            element.resource.content.geometry.coordinates = transResult
          } else if (transformType === 'gcj02-wgs84') {
            const transResult = gcj02towgs84(
              coordinates[0],
              coordinates[1]
            ) as GeojsonCoordinate
            element.resource.content.geometry.coordinates = transResult
          }
        } else if (MapElementEnum.LINE === type) {
          const coordinates = element.resource?.content.geometry
            .coordinates as GeojsonCoordinate[]
          if (transformType === 'wgs84-gcj02') {
            coordinates.forEach((coordinate, i, arr) => {
              arr[i] = wgs84togcj02(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          } else if (transformType === 'gcj02-wgs84') {
            coordinates.forEach((coordinate, i, arr) => {
              arr[i] = gcj02towgs84(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          }
          element.resource.content.geometry.coordinates = coordinates
        } else if (MapElementEnum.POLY === type) {
          const coordinates = element.resource?.content.geometry
            .coordinates[0] as GeojsonCoordinate[]
          if (transformType === 'wgs84-gcj02') {
            coordinates.forEach((coordinate, i, arr) => {
              arr[i] = wgs84togcj02(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          } else if (transformType === 'gcj02-wgs84') {
            coordinates.forEach((coordinate, i, arr) => {
              arr[i] = gcj02towgs84(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          }
          element.resource.content.geometry.coordinates = [coordinates]
        }
      }
    }

    // 加载航线
    watch(() => store.state.waylineInfo,
      data => {
        getWayLineFileById(data.id).then(res => {
          // console.log('res:' + res)
          if (res.code !== 0) {
            return
          }
          wayLineData.wayLineName = res.data.wayLineName
          wayLineData.wayLinePhotos = res.data.wayLinePhoto
          wayLineData.wayLineDis = res.data.wayLineDis
          wayLineData.wayLineTime = res.data.wayLineTime
          wayLineData.wayLineNum = res.data.wayLinePointNum
          console.log('开始处理地图')
          store.commit('SET_SELECT_WAYLINE', res.data)
        })
      },
      {
        deep: true
      }
    )
    let player:any
    function videoPlayer2 (id:string, e: string) {
      if (id === 'smallVideo') {
        if (mpegts.getFeatureList().mseLivePlayback) {
          const videoElement:HTMLMediaElement = document.getElementById(id)
          const bigPlayer = mpegts.createPlayer({

            type: 'flv', // could also be mpegts, m2ts, flv
            isLive: true,
            url: e
          }, {
            liveBufferLatencyChasing: true,
            liveBufferLatencyMaxLatency: 0.9,
            liveBufferLatencyMinRemain: 0.2
          })
          bigPlayer.attachMediaElement(videoElement)
          bigPlayer.load()
          bigPlayer.play()
        }
      } else if (id === 'smallVideo1') {
        if (mpegts.getFeatureList().mseLivePlayback) {
          if (player !== undefined && player !== null) {
            player.destroy()
          }
          const videoElement:HTMLMediaElement = document.getElementById(id)
          player = mpegts.createPlayer({
            type: 'flv', // could also be mpegts, m2ts, flv
            isLive: true,
            url: e
          }, {
            liveBufferLatencyChasing: true,
            liveBufferLatencyMaxLatency: 0.9,
            liveBufferLatencyMinRemain: 0.2
          })
          player.attachMediaElement(videoElement)
          player.load()
          player.play()
        }
      }
    }
    function changeMapAndVideo () {
      state.mapType = !state.mapType
    }

    const showLiveData = async () => {
      droneList.value = []
      cameraList.value = []
      videoList.value = []
      droneSelected.value = null
      cameraSelected.value = null
      videoSelected.value = null
      await getLiveCapacityNew({})
        .then(res => {
          console.log(res)
          if (res.code === 0) {
            if (res.data === null) {
              console.warn('warning: get live capacity is null!!!')
              return
            }
            const resData: Array<[]> = res.data
            console.log('live_capacity:', resData)
            livestreamSource.value = resData

            const temp: Array<SelectOption> = []
            const flvList: Array<FlvList> = []
            if (livestreamSource.value) {
              livestreamSource.value.forEach((ele: any) => {
                temp.push({ label: ele.name + '-' + ele.sn, value: ele.sn, more: ele.cameras_list })
                ele.cameras_list.forEach((item:any) => {
                  if (item.name !== 'DJI Dock Camera') {
                    const itemVideoId = ele.children_sn + '/' + item.index + '/' + ('normal-0')
                    flvList.push({ sn: ele.children_sn, value: itemVideoId, name: item.name })
                  } else {
                    itemVideoId.value = ele.sn + '/' + item.index + '/' + ('normal-0')
                  }
                })
              })
              droneList.value = temp
              flvIndexList.value = flvList
            }
          }
        })
        .catch(error => {
          message.error(error)
          console.error(error)
        })
    }

    watch(
      () => store.state.nowClickDevice,
      newData => {
        const isDock = store.state.nowClickDeviceType
        if (isDock) {
          droneSelected.value = newData.gateway.sn
          claritySelected.value = 0
          droneList.value.forEach((item:SelectOption) => {
            if (item.value === newData.gateway.sn) {
              onDroneSelect(item)
            }
          })

          if (cameraList.value.length !== 0) {
            cameraSelected.value = cameraList.value[0].value
          }

          if (flvIndexList.value.length !== 0) {
            videoId.value = flvIndexList.value[0].value
            onStartVideo('smallVideo1', newData.sn, flvIndexList.value[0].value)
          }

          dockVideoShowState.value = true
          // 加载机场直播
          onStartVideo('smallVideo', newData.gateway.sn, itemVideoId.value)
        }
      },
      {
        deep: true
      }
    )

    watch(
      () => store.state.deviceState.deviceInfo,
      newData => {
        const deviceSn = store.state.nowClickDevice.sn
        const dockSn = store.state.nowClickDevice.gateway.sn

        if (newData[deviceSn] === undefined || newData[deviceSn] === null) {
          deviceVideoShowState.value = false
          return
        }

        showLiveData()

        claritySelected.value = 0
        droneList.value.forEach((item:SelectOption) => {
          if (item.value === dockSn) {
            onDroneSelect(item)
          }
        })
        droneSelected.value = dockSn
        if (cameraList.value.length !== 0) {
          cameraSelected.value = cameraList.value[0].value
        }

        if (flvIndexList.value.length !== 0) {
          videoId.value = flvIndexList.value[0].value
        }

        if (deviceSn !== undefined && newData[deviceSn] !== undefined) {
          if (newData[deviceSn].mode_code !== 14) {
            deviceVideoShowState.value = true
            if (deviceVideoShowState.value === true && deviceLiveState.value === false) {
              // 开始直播
              if (newData[deviceSn].cameras === undefined) {
                const videoIdStr = deviceSn + '/' + newData[deviceSn].payload[0].payload_index + '/' + ('normal-0')
                onStartVideo('smallVideo1', deviceSn, videoIdStr)
              } else {
                const videoIdStr = deviceSn + '/' + newData[deviceSn].cameras[0].payload_index + '/' + ('normal-0')
                onStartVideo('smallVideo1', deviceSn, videoIdStr)
              }
            }
          } else {
            if (player !== undefined && player !== null) {
              player.destroy()
            }
            deviceVideoShowState.value = false
            deviceLiveState.value = false
          }
        }
      },
      {
        deep: true
      }
    )

    const onStartVideo = async (id:string, sn:string, videoId:string) => {
      // const hide = message.loading('AI解析中, 请稍后...', 0)
      const liveURL = config.rtmpURL + '/' + sn
      // await stopLivestream({
      //   url: liveURL,
      //   video_id: videoId,
      //   url_type: 1,
      //   video_quality: 1
      // })
      await startLivestream({
        url: liveURL,
        video_id: videoId,
        url_type: 1,
        video_quality: 1,
        sn: sn
      })
        .then(async res => {
          // await startLivestreamAi({
          //   url: liveURL,
          //   video_id: videoId,
          //   url_type: 1,
          //   video_quality: 1,
          //   sn: sn
          // })
          // hide()
          if (res.code !== 0 && res.code !== 513003) {
            return
          }
          const pullStream = config.rtmpURL.replace('rtmp', 'https').replace(':8080', '') + '/'
          if (res.code === 513003) {
            // 正在直播
            const flvUrl = pullStream + sn + '.live.flv'
            console.log('正在直播，拉流地址：' + flvUrl)
            videoPlayer2(id, flvUrl)
          } else {
            const url = res.data.url
            const videoElement = videowebrtc.value
            console.log('开始直播：', url)
            console.log(videoElement)

            const flvUrl = pullStream + sn + '.live.flv'
            console.log(flvUrl)
            videoPlayer2(id, flvUrl)
          }

          if (id === 'smallVideo1') {
            deviceLiveState.value = true
          }
          liveState.value = true
        })
        .catch(err => {
          console.error('1111' + err)
          deviceLiveState.value = false
        })
    }

    // 切换清晰度
    const onUpdateQuality = () => {
      if (!liveState.value) {
        message.info('直播未开始')
        return
      }
      setLivestreamQuality({
        video_id: videoId.value,
        video_quality: claritySelected.value
      }).then(res => {
        if (res.code === 0) {
          message.success('清晰度已设置' + clarityList[claritySelected.value].label)
        }
      })
    }

    // 切换镜头
    const onSwitch = () => {
      if (lensSelected.value === undefined || lensSelected.value === nonSwitchable) {
        message.info('The ' + nonSwitchable + ' lens cannot be switched, please select the lens to be switched.', 8)
        return
      }
      changeLivestreamLens({
        video_id: videoId.value,
        video_type: lensSelected.value
      }).then(res => {
        if (res.code === 0) {
          message.success('切换成功')
        }
      })
    }
    return {
      draw,
      mouseMode,
      drawVisible,
      livestreamOthersVisible,
      livestreamAgoraVisible,
      osdVisible,
      pin,
      state,
      M30,
      deviceInfo,
      EGear,
      EModeCode,
      str,
      EDockModeCode,
      dockControlPanelVisible,
      setDockControlPanelVisible,
      onCloseControlPanel,
      droneControlPanelVisible,
      setDroneControlPanelVisible,
      onCloseDroneControlPanel,
      NetworkStateTypeEnum,
      NetworkStateQualityEnum,
      RainfallEnum,
      DroneInDockEnum,
      closeLivestreamOthers,
      closeLivestreamAgora,
      qualityStyle,
      wayLineData,
      changeMapAndVideo,
      root,
      droneSelected,
      cameraSelected,
      lensSelected,
      videoSelected,
      claritySelected,
      droneList,
      cameraList,
      clarityList,
      videoList,
      onDroneSelect,
      onCameraSelect,
      onVideoSelect,
      onUpdateQuality,
      onLensSelect,
      lensList,
      dockVideoShowState,
      deviceVideoShowState
    }
  }
})
</script>

<style lang="scss" scoped>

.g-map-wrapper {
  height: 100%;
  width: 100%;

  .g-action-panel {
    position: absolute;
    top: 16px;
    right: 16px;
    .g-action-item {
      width: 28px;
      height: 28px;
      background: white;
      color: $primary;
      border-radius: 2px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 2px;
    }
    .g-action-item:hover {
      border: 1px solid $primary;
      border-radius: 2px;
    }
  }
  .selection {
    border: 1px solid $primary;
    border-radius: 2px;
  }

  // antd button 光晕
  &:deep(.ant-btn){
    &::after {
      display: none;
    }
  }
}

.g-map-yy {
  //z-index: 999999999;
  position: absolute;
  bottom: 32px;
  width: 100%;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, .49));
}

.way-line-title {
  top: 10px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  width: 22rem;
  border-width: 0;
  border-radius: 0.4rem;
  position: fixed;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;

  font-weight: bold;
  font-size: 20px;
  color: white;
}

.g-map-db {
  border-left: 1px solid #4f4f4f;
  z-index: 99999999;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #232323;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
  font-size: 14px;
  line-height: 22px;
}

.g-map-yy-1 {
  //z-index: 999999999;
  position: absolute;
  line-height: 28px;
  bottom: 23px;
  width: 100%;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
}

.g-map-yy-2 {
  //z-index: 999999999;
  position: absolute;
  padding-left: 16px;
  top: 0;
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .08) 71%, transparent);
  text-shadow: 0 0 1px rgba(0, 0, 0, .6);
}
.osd-panel {
  z-index: 100;
  position: absolute;
  margin-left: 10px;
  left: 0;
  top: 10px;
  width: 480px;
  background: #232323;
  color: #fff;
  border-radius: 2px;
  //opacity: 0.8;
}

.osd-panel1 {
  z-index: 100;
  position: absolute;
  margin-left: 10px;
  left: 480px;
  bottom: 10px;
  width: 550px;
  background: #232323;
  color: #fff;
  border-radius: 2px;
  //opacity: 0.8;
}
.osd > div:not(.dock-control-panel) {
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.battery-slide {
  .capacity-percent {
    background: #00ee8b;
  }
  .return-home {
    background: #ff9f0a;
  }
  .landing {
    background: #f5222d;
  }
  .white-point {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
    bottom: -0.5px;
  }
  .battery {
    background: #141414;
    color: #00ee8b;
    margin-top: -10px;
    height: 20px;
    width: auto;
    border-left: 1px solid #00ee8b;
    padding: 0 5px;
  }
}
.battery-slide > div {
  position: absolute;
  min-height: 2px;
  border-radius: 2px;
}

.liveview {
  position: absolute;
  color: #fff;
  z-index: 1;
  right: 15px;
  bottom: 10px;
  text-align: center;
  width: 800px;
  height: 720px;
  background: #232323;
}

.liveview-small {
  position: absolute;
  color: #fff;
  z-index: 1;
  right: 15px;
  bottom: 20px;
  text-align: center;
  width: 360px;
  height: 330px;
  background: #232323;
}

.liveview-big {
  border-left: 0.5px solid #4f4f4f;
  position: absolute;
  color: #fff;
  z-index: 9999;
  text-align: center;
  background: #232323;
}

.liveview-big-2 {
  border-left: 0.5px solid #4f4f4f;
  position: absolute;
  color: #fff;
  z-index: 9999;
  right: 15px;
  bottom: 10px;
  text-align: center;
  width: 25%;
  height: 25%;
  background: #232323;
}
</style>
